import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FaChevronUp } from 'react-icons/fa';

interface DropdownProps {
  selectedOption: { value: string, label: string } | null;
  options: Array<{ label: string; value: string }>;
  onChange: (option: { value: string, label: string }) => void;
  size: string;
}

const sizeToPixels = (size: string) => {
  return size === 'small'
    ? '160px'
    : size === 'medium' ? '200px'
    : '300px'
}

const Dropdown: React.FC<DropdownProps> = ({ selectedOption, options, onChange, size='medium' }) => {
  const handleChange = (value: string) => {
    const option = options.find(opt => opt.value === value);
    if (option) {
      onChange(option);
    }
  };

  return (
    <Listbox as="div" style={{ minWidth: sizeToPixels(size) }} className={`relative`} value={selectedOption?.value} onChange={handleChange}>
      {() => (
        <>
          <Listbox.Button style={{ minWidth: sizeToPixels(size) }} className={`relative cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}>
            {selectedOption ? selectedOption.label : 'Select'}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <FaChevronUp className="h-4 w-4 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options style={{ minWidth: sizeToPixels(size) }} className={`absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
              {options.map((option, idx) => (
                <Listbox.Option
                  key={idx}
                  style={{ minWidth: sizeToPixels(size) }}
                  className={({ selected }) =>
                    `relative cursor-pointer select-none py-2 pl-6 pr-6 ${
                      selected ? 'bg-blue-500 text-white' : 'text-gray-900 hover:bg-blue-500 hover:text-white'
                    }`
                  }
                  value={option.value}
                >
                  {() => (
                    <>
                      <span>
                        {option.label}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </>
      )}
    </Listbox>
  );
};

export default Dropdown;
