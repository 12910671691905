export const EVENT_OPTIONS = [
  { value: 'conflict-events', label: 'Humanitarian - Development' },
  { value: 'displacement-events', label: 'Population' },
  { value: 'crisis-funding-events', label: 'Interventions' },
  { value: 'admin-data', label: 'Admin' },
];

export const LOCALITY_OPTIONS = [
  { value: 'lb', label: 'Lebanon' }
];

export const EVENT_TYPE_OPTIONS = {
  'conflict-events': [
    { value: 'acled', label: 'ACLED' },
    { value: 'inew', label: 'INEW' },
  ],
  'crisis-funding-events': [
    { value: 'financial_requirements', label: 'Financial Requirements' }
  ],
  'displacement-events': [
    { value: 'population_count', label: 'Population Count' },
    { value: 'demographics', label: 'Demographics' },
    { value: 'displacement_data', label: 'Displacement Data' }
  ],
  'admin-data': [
    { value: 'buildings', label: 'Buildings' },
    { value: 'land_use', label: 'Land Use' },
    { value: 'natural', label: 'Natural' },
    { value: 'localities', label: 'Localities' },
    { value: 'health_care', label: 'Health Care' },
    { value: 'government', label: 'Government' },
    { value: 'finance', label: 'Finance' },
    { value: 'emergency', label: 'Emergency' },
    { value: 'education', label: 'Education' },
    { value: 'communication', label: 'Communication' },
    { value: 'commercial', label: 'Commercial' }
  ]
}
